<template>
  <div>
    <div class="table-header-tabs">
      <div
        v-for="(tab, index) in headerTabs"
        :key="tab"
        class="header-tab"
        :class="{ 'header-tab--active': activeTabIndex === index }"
        @click="activeTabIndex = index"
      >
        <p>{{tab}}</p>
      </div>
    </div>

    <b-table
      ref="table"
      class="lms-table manage-students table-homework scroll"
      id="table-homework"
      :items="filteredUsers"
      :fields="fields()"
      primary-key="username"
      borderless
      responsive
      :tbody-transition-props="{ name: 'flip-list' }"
      stacked="sm"
    >
      <template #cell(full_name)="data">
        <div>
          {{ getFullName(data.item.user.firstName, data.item.user.lastName) }}
        </div>
      </template>

      <template #cell(username)="data">
        <div>
          {{ data.item.user.username }}
        </div>
      </template>

      <template #cell(updated_at)="data">
        <div>
          {{ formatDateCreation(data.item.updated_at) }}
        </div>
      </template>

      <template #cell(taskTitle)="data">
        <div class="task-title">
          {{ data.item.taskTitle }}
        </div>
      </template>

      <template #cell(status)="data">
        <div>
          <ProgressStatus iconPosition="left" :status="computeStatus(data.item)"/>
        </div>
      </template>

      <template #cell(access)="data">
        <router-link :to="{path : '/check-homeworks', query: {homeworkId: data.item.chatId}}">
          <AddButton
            :title="$t('buttonLabels.check')"
            size="small"
            variant="secondary"
          /></router-link>

      </template>

      <template #custom-foot>
        <tr role="row" ref="footer">
          <th colspan="8" class="table-footer"
            :class="{'table-footer--invisible': isChatsLoaded}">
        <tr >
          <td >{{ $t('label.loading') }}</td>
        </tr>
        </th>
        </tr>
      </template>
    </b-table>
  </div>

</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AddButton from '@/components/Buttons/AddButton.vue';
import ProgressStatus from '@/components/ProgressStatus.vue';
import { ActivityStatuses } from '@/utils/statusConstants';

export default {
  name: 'TableHomeworks',
  components: {
    ProgressStatus,
    AddButton,
  },
  async created() {
    await this.fetchChats();

    this.$nextTick(() => {
      this.createObserverOfTableFooter(this.$refs.footer, () => {
        if (!this.isAllStudentsLoaded) {
          // this.getMoreUsers();
        }
      });
    });
  },
  data() {
    return {
      sortBy: 'username',
      sortDesc: false,
      currentPage: 1,
      perPage: 15,
      selected: [],
      allSelected: false,
      isRequestBusy: false,
      observer: null,
      isAllStudentsLoaded: false,
      activeTabIndex: 0,
    };
  },
  methods: {
    ...mapActions('chats', ['fetchChats']),
    formatDateCreation(date) {
      const timeToSet = new Date(date);
      return new Intl.DateTimeFormat(this.$i18n?.locale === 'en' ? 'en' : 'ru', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      }).format(timeToSet);
    },
    createObserverOfTableFooter(element, callback) {
      this.observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            // if element is visible - triggers callback
            if (entry.intersectionRatio > 0) {
              callback();
            }
          });
        },
        {
          root: null,
          threshold: 1.0,
        },
      );

      this.observer.observe(element);
    },
    fields() {
      return [
        {
          key: 'full_name',
          label: this.$t('label.user'),
          class: 'user-name-column',
        },
        {
          key: 'username',
          label: 'E-mail',
          class: 'email-column',
        },
        {
          key: 'taskTitle',
          label: this.$t('label.task'),
          class: 'date-subscribe-column',
        },
        {
          key: 'updated_at',
          label: this.$t('label.sent'),
          class: 'date-subscribe-column',
        },

        {
          key: 'status',
          label: this.$t('label.status'),
          class: 'date-subscribe-column',
        },
        {
          key: 'access',
          label: '',
          class: 'access-column',
        },
      ];
    },
    computeStatus(userData) {
      if (userData.statistic.status === ActivityStatuses.done) {
        return {
          value: 'done',
          label: 'label.accepted',
        };
      }
      if (userData.statistic?.status === ActivityStatuses.rejected) {
        return {
          value: 'rejected',
          label: 'label.rejected',
        };
      }
      return {
        value: 'not-started',
        label: 'label.onTheCheck',
      };
    },
    getFullName(firstName, lastName) {
      return `${firstName} ${lastName}`;
    },
  },
  computed: {
    ...mapGetters({ chats: 'chats/getChatList' }),
    headerTabs() {
      return [
        this.$t('label.allTasks'), this.$t('label.needToBeChecked'), this.$t('label.checked'),
      ];
    },

    isChatsLoaded() {
      return !!this.chats?.length;
    },
    allSelectedUsersId() {
      return this.users.map((u) => u._id);
    },
    sortDirection() {
      return this.sortDesc ? 'descending' : 'ascending';
    },
    users() {
      const userChats = [];
      if (this.chats?.length) {
        this.chats.forEach((value) => {
          const homework = {};
          homework.user = value.User;
          homework.taskTitle = value.Task.name;
          homework.statistic = value.Statistics;
          homework.updated_at = value.LastMessageSentAt;
          homework.chatId = value.TaskChatID;
          userChats.push(homework);
        });
      }
      return userChats;
    },
    filteredUsers() {
      if (this.activeTabIndex === 1) {
        return this.users.filter((t) => t?.statistic.status === ActivityStatuses.doing);
      }
      if (this.activeTabIndex === 2) {
        return this.users.filter((t) => t?.statistic.status === ActivityStatuses.rejected
        || t?.statistic.status === ActivityStatuses.done);
      }
      return this.users;
    },
  },
  beforeDestroy() {
    if (this.observer?.unobserve) {
      this.observer.unobserve(this.$refs.footer);
    }
  },
};
</script>

<style scoped>
::v-deep .progress-status {
  justify-content: start;
}
</style>
